<template>
  <div id="v-pills-personal" role="tabpanel" aria-labelledby="v-pills-personal-tab">
    <div class="col content">
      <h5 class="text-primary p-3">Personal details
      </h5>
      <hr />
      <div class="p-4" id="display_profile">
        <div class="row">
          <div class="col-4">
            <label class="text-secondary">About me<span style="color: red">*</span></label>
          </div>
          <div class="col-8" v-if="user.personal_details"><p style="word-wrap:break-word">{{ this.user.personal_details.resume_summary}}</p></div>
          <div class="col-8" v-else><div class="gradient" style="height:10px;width:350px;"></div></div>
        </div>
        <div class="row">
          <div class="col-4">
            <label class="text-secondary">Name<span style="color: red">*</span></label>
          </div>
          <div class="col-8" v-if="user.personal_details"><p>{{ this.user.name }}</p></div>
          <div class="col-8" v-else><div class="gradient" style="height:10px;width:220px;"></div></div>
        </div>
        <div>
          <div class="row">
            <div class="col-4">
              <label class="text-secondary">Current city<span style="color: red">*</span></label>
            </div>
            <div class="col-8" v-if="user.personal_details">
              <p v-if="this.user.personal_details.user_city">{{this.user.personal_details.user_city.city_name}}</p>
              <p v-if="!this.user.personal_details.user_city">-</p>
            </div>
            <div class="col-8" v-else><div class="gradient" style="height:10px;width:150px;"></div></div>
          </div>
          <div class="row">
            <div class="col-4">
              <label class="text-secondary">Sub location<span style="color: red">*</span></label>
            </div>
            <div class="col-8" v-if="user.personal_details">
              <p v-if="this.user.personal_details.sub_location">{{this.user.personal_details.sub_location.sublocation_name}}</p>
              <p v-if="!this.user.personal_details.sub_location">-</p>
            </div>
            <div class="col-8" v-else><div class="gradient" style="height:10px;width:200px;"></div></div>
          </div>
          <div class="row">
            <div class="col-4">
              <label class="text-secondary">Gender<span style="color: red">*</span></label>
            </div>
            <div class="col-8" v-if="user.personal_details">
              <p v-if="this.user.personal_details.gender">{{getGender(this.user.personal_details.gender)}}</p>
              <p v-if="!this.user.personal_details.gender">-</p>
            </div>
            <div class="col-8" v-else><div class="gradient" style="height:10px;width:150px;"></div></div>
          </div>
          <div class="row">
            <div class="col-4">
              <label class="text-secondary">Date of birth<span style="color: red">*</span></label>
            </div>
            <div class="col-8" v-if="user.personal_details">
              <p v-if="this.user.personal_details.dob">{{this.user.personal_details.dob.split('-').reverse().join('-')}}</p>
              <p v-if="!this.user.personal_details.dob">-</p>
            </div>
            <div class="col-8" v-else><div class="gradient" style="height:10px;width:100px;"></div></div>
          </div>
          <div class="row">
            <div class="col-4">
              <label class="text-secondary">Marital Status<span style="color: red">*</span></label>
            </div>
            <div class="col-8" v-if="user.personal_details">
              <p v-if="this.user.personal_details.marital_status">{{ getMaritalStatus(this.user.personal_details.marital_status) }}</p>
              <p v-if="!this.user.personal_details.marital_status">-</p>
            </div>
            <div class="col-8" v-else><div class="gradient" style="height:10px;width:80px;"></div></div>
          </div>
          <div class="row">
            <div class="col-4">
              <label class="text-secondary">Languages known<span style="color: red">*</span></label>
            </div>
            <div class="col-8" v-if="user.personal_details">
              <p v-if="this.user.languages_known">
                {{ JSON.parse(this.user.languages_known.language_known).join()}}
              </p>
              <p v-if="!this.user.languages_known">-</p>
            </div>
            <div class="col-8" v-else><div class="gradient" style="height:10px;width:150px;"></div></div>
          </div>
          
          <div class="row">
            <div class="col-4">
              <label class="text-secondary">Job role<span style="color: red">*</span></label>
            </div>
            <div class="col-8" v-if="user.personal_details">
              <p v-if="this.user.careerPreferencesDetail.fst_prefered_role || this.user.careerPreferencesDetail.snd_prefered_role || this.user.careerPreferencesDetail.trd_prefered_role"><span>{{this.setJobRole(this.user.careerPreferencesDetail)}}</span></p>
              <p v-else>-</p>
            </div>
            <div class="col-8" v-else><div class="gradient" style="height:10px;width:250px;"></div></div>
          </div>
          <div class="row">
            <div class="col-4">
              <label class="text-secondary">Skill<span style="color: red">*</span></label>
            </div>
            <div class="col-8" v-if="user.personal_details">
              <p v-if="this.user.personal_details.skills">{{JSON.parse(this.user.personal_details.skills)}}</p>
              <p v-if="!this.user.personal_details.skills">-</p>
            </div>
            <div class="col-8" v-else><div class="gradient" style="height:10px;width:200px;"></div></div>
          </div>
          <div class="row mb-2">
            <div class="col-4">
              <label class="text-secondary">Current Address</label>
            </div>
            <div class="col-8" v-if="user.personal_details">
              <p v-if="this.user.personal_details.current_address">{{ this.user.personal_details.current_address }}</p>
              <p v-if="!this.user.personal_details.current_address">-</p>
            </div>
            <div class="col-8" v-else><div class="gradient" style="height:10px;width:300px;"></div></div>
          </div>
          <div class="row mb-2">
            <div class="col-4">
              <label class="text-secondary">Permanent Address</label>
            </div>
            <div class="col-8" v-if="user.personal_details">
              <p v-if="this.user.personal_details.permanent_address">{{ this.user.personal_details.permanent_address }}</p>
              <p v-if="!this.user.personal_details.permanent_address">-</p>
            </div>
            <div class="col-8" v-else><div class="gradient" style="height:10px;width:320px;"></div></div>
          </div>
          <div class="row mb-2">
            <div class="col-4">
              <label class="text-secondary">Alternate Contact Number</label>
            </div>
            <div class="col-8" v-if="user.personal_details">
              <p v-if="this.user.personal_details.contact_no">{{ this.user.personal_details.contact_no }}</p>
              <p v-if="!this.user.personal_details.contact_no">-</p>
            </div>
            <div class="col-8" v-else><div class="gradient" style="height:10px;width:150px;"></div></div>
          </div>
        </div>
      </div>
      <hr />
      <div class="p-4">
        <div class="row mb-2">
          <div class="col-lg-4">
            <label class="text-secondary">Uploaded resume</label>
          </div>
          <div class="col-lg-8 truncate-words" v-if="this.user.personal_details">
            <p v-show="this.user.personal_details.resume_path"><a class="text-decoration-none" :href="$S3_bucket_url+this.user.personal_details.resume_path">{{getResumePath(this.user.personal_details.resume_path)}}</a></p>
            <p v-show="!this.user.personal_details.resume_path">-</p>
          </div>
          <div class="col-lg-8 truncate-words" v-else>
            <div class="gradient" style="height:10px;width:220px;"></div>
          </div>
        </div>
      </div>
      <hr />
    </div>
  </div>
</template>

<script>
import Actions from '../../libraries/apiActions.js';
export default {
  name: "candidate_profile",
  props: ["user"],
  data: function () {
    return {
      // validate: new this.$valid(),
      action: new Actions(),
      user_city : null,
      user_state : null,
      languages:[],
      skills:[],
      jobrole:[],
      u_jobrole:[],
      t_city:null,
      gender:{
        options:[
          {value:1, text:'Male'},
          {value:2, text:'Female'},
          {value:3, text:'Both'}
        ]
      },
      maritalStatus:{
        options:[
          {value:1, text:'Single'},
          {value:2, text:'Married'}
        ]
      },
      communication_rate:{
        options:[
          {value:1, text:'Low'},
          {value:2, text:'Moderate'},
          {value:3, text:'Good'},
          {value:4, text:'Very Good'}          
        ]
      },
      e_user:{
        name : null,
        city : null,
        sub_location : null,
        gender : null,
        dob : null,
        marital_status : null,
        language_known : [],
        communication_rating : 1,
        jobrole: [],
        skils: [],
        current_address: null,
        permanent_address: null,
        alternate_contact_no: null
      },
      user_gender:null,
      user_marital_status:null,
      user_role:[],
      limit_error:false,
      error_msg:'',
      subCity: false,
      can_update: 0 ,
      sublocations:[],
      resumeEdit: false,
      resume: null,
      update:{
        resume:null
      }
    };
  },
  beforeMount(){
      this.getLanguages();
      this.getSkills();
      this.getJobrole();
  },
  methods: {
    showSubcity: function () {
      this.subCity = false;
      if (this.e_user.city != null) {
        this.getCities();
      }
    },
    getCities: function(){
      this.sublocations = [];
      this.action.getSubcity(this.e_user.city).then((data) => {
        if(data.sublocations){
          this.subCity = true;
          data.sublocations.forEach((element) => {
            this.sublocations.push({
              value: element.sublocation_id,
              text: element.sublocation_name,
            });
          });
        }
      });
    },
    getLanguages(){
        this.action.getLanguages().then(data => {
          data.languages.forEach((element) => {
            this.languages.push({
              value:element.id,
              text:element.language
            })
          })
        }).catch(err =>{
          console.log(err);
        })
    },
    getSkills: function(){
      this.action.getSkills().then(data => {
        data.skills.forEach((element) => {
          this.skills.push({
            value:element.id,
            text:element.skill
          })
        })
      }).catch(err =>{
        console.log(err);
      })
    },
    getJobrole: function(){
      this.action.getJobrole().then((data) => {
        data.jobroles.forEach((element) => {
          this.jobrole.push({
            value: element.role_id,
            text: element.role,
          });
        });
      });
    },
    setJobRole: function(role){
      let u_role = [];
      for(var i=0;i<this.jobrole.length;i++){
        if(this.jobrole[i].value == role.fst_prefered_role){
          u_role.push(this.jobrole[i].text)
        }
        if(this.jobrole[i].value == role.snd_prefered_role){
          u_role.push(this.jobrole[i].text)
        }
        if(this.jobrole[i].value == role.trd_prefered_role){
          u_role.push(this.jobrole[i].text)
        }
      }
      return u_role.join();
    },
    getGender(id){
      this.gender.options.forEach((g) => {
        if(g.value == id){
          this.user_gender = g.text;
        }
      })
      return this.user_gender;
    },
    getMaritalStatus(id){
      this.maritalStatus.options.forEach((m) => {
        if(m.value == id){
          this.user_marital_status = m.text; 
        }
      })
      return this.user_marital_status;
    },
    getResumePath: function(path) {
      if(path != null){
        let res = path.split("/");
        return res[res.length-1]
      }else{
        return null;
      }
    },
  },
};
</script>

<style>
.edit-button{
  position: absolute;
  right: 15px;
  top: 25px;
  cursor: pointer;
}
.edit-button img{
  width: 25px;
}
.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--box .v-select__selections, .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed .v-select__selections{
  min-height:55px !important;
}
.v-text-field__details{
  display: none !important;
}
.v-input__slot{
  margin-bottom:0 !important;
}
</style>
<style scoped>
input{
  padding: 28px 0px 28px 15px !important;
}
.error-feedback{
  position: absolute;
}
</style>